// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-donazioni-js": () => import("./../../../src/pages/donazioni.js" /* webpackChunkName: "component---src-pages-donazioni-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libro-js": () => import("./../../../src/pages/libro.js" /* webpackChunkName: "component---src-pages-libro-js" */),
  "component---src-pages-manifesti-js": () => import("./../../../src/pages/manifesti.js" /* webpackChunkName: "component---src-pages-manifesti-js" */),
  "component---src-pages-manutenzione-js": () => import("./../../../src/pages/manutenzione.js" /* webpackChunkName: "component---src-pages-manutenzione-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-pages-squadre-js": () => import("./../../../src/pages/squadre.js" /* webpackChunkName: "component---src-pages-squadre-js" */),
  "component---src-pages-storia-js": () => import("./../../../src/pages/storia.js" /* webpackChunkName: "component---src-pages-storia-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articoli-js": () => import("./../../../src/templates/articoli.js" /* webpackChunkName: "component---src-templates-articoli-js" */),
  "component---src-templates-galleria-js": () => import("./../../../src/templates/galleria.js" /* webpackChunkName: "component---src-templates-galleria-js" */),
  "component---src-templates-manifesto-js": () => import("./../../../src/templates/manifesto.js" /* webpackChunkName: "component---src-templates-manifesto-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

