module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jesolo San Dona Basket","short_name":"JSDB","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"/usr/src/app/www/src/icons/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a1e1839f58fdb80e12d4ce1e6bdf7775"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/articoli/","/squadre/","/storia/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Jesolo San Donà Basket","titleTemplate":"%s - JSDB","language":"it","openGraph":{"type":"website","locale":"it","url":"https://www.jesolosandonabasket.it/","site_name":"JSDB"},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image"},"facebook":{"appId":3717611501596855}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-167982628-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"secure":true,"sameSite":"none"},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"250377889359830","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat"]}},
    }]
